<template>
  <div eagle-form-item="site-admin-group">

    <v-card v-if="siteAdminGroup">
      <v-card-title>
        <span class="mr-4">{{'module.site_admin_group'| t}}: </span>
        <router-link :to="siteAdminGroupRoute" target="_blank" class="text-decoration-underline">
          <span>{{siteAdminGroup.name}}</span>
        </router-link>
      </v-card-title>

      <v-card-subtitle>
        <div class="mb-2">
          <p v-html="nl2br(siteAdminGroup.description)"></p>
        </div>
      </v-card-subtitle>

      <v-card-text v-if="siteAdminGroup.site_admins">
        <siteAdmin
          v-for="siteAdmin in siteAdminGroup.site_admins"
          :key="siteAdmin.id"
          :siteAdmin="siteAdmin"
        ></siteAdmin>
      </v-card-text>

      <v-card-actions v-if="!disabled">
        <v-btn
          color="primary"
          small
          @click="choiceSiteAdminGroup"
        >
          {{'action.update'| t}}{{'module.site_admin_group'| t}}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-btn
      v-if="!siteAdminGroup && !disabled"
      color="primary"
      small
      @click="choiceSiteAdminGroup"
    >
      {{'action.update'| t}}{{'module.site_admin_group'| t}}
    </v-btn>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  data: () => ({
    siteAdminGroup: null,
  }),
  mounted() {
    this.loadGroups()
  },
  methods: {
    async loadGroups() {
      this.data = window.eagleLodash.cloneDeep(this.storeData)
      await this.readSiteAdmin()
    },
    async readSiteAdmin() {
      if(!this.data) {
        return
      }

      try {
        this.siteAdminGroup = await this.$api.collection.siteAdminGroupApi.read(this.data)
      } catch (error) {
        console.error(error)
      }
    },
    choiceSiteAdminGroup() {
      this.$siteAdminGroupSelector({
        usedList: this.usedList,
        usedListMatchCallback: (usedListRow, row) => usedListRow == row.id,
        max: 1,
        applyCallback: async (siteAdminGroup) => {
          this.data = siteAdminGroup.id
          this.siteAdminGroup = siteAdminGroup
          await this.$helper.delay(0.5)
          this.loadGroups()
        },
      })
    },
  },
  computed: {
    siteAdminGroupRoute() {
      if(!this.siteAdminGroup) return {}
      return {
        name: 'site-admin-group-update',
        params: {
          target: this.siteAdminGroup.id
        }
      }
    },
    usedList() {
      if(!this.data) return null
      return [this.data]
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.sync()
      },
    },
  },
  components: {
    siteAdmin: () => import('./siteAdminGroup/siteAdmin.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
